#footer {
    width: 100%;
    min-height: 360px;
    background-color: #333333;
}

.footer-top {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: #e6e6e6;
    i {
        color: #666666;
    }
}

.footer-botton {
    min-height: 310px;
}

.footer-info {
    padding-top: 80px;
    display: flex;
    width: 100%;
    min-height: 250px;
    @include breakpoint($mobile) {
        padding-top: 40px;
    }
    div {
        width: calc(100%/3);
        @include breakpoint($mobile-tablet) {
            width: calc(100%/2);
        }
        @include breakpoint($mobile) {
            width: calc(100%);
        }
        ul {
            li {
                display: flex;
                margin-bottom: 30px;
                span {
                    color: #fff;
                    display: flex;
                    min-width: 80px;
                }
                p {
                    color: #cccccc;
                }
                .add {
                    width: 290px;
                    line-height: 30px;
                    position: relative;
                    top: -5px;
                    @include breakpoint($mobile) {
                        width: calc(100%);
                    }
                }
            }
        }
    }
}

.footer-copy {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-top: 1px solid #cccccc;
    p {
        color: #cccccc;
        a {
            color: #cccccc;
        }
        @include breakpoint($mobile) {
            font-size: 13px;
        }
    }
}

.map {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 3px;
    width: 70px;
    height: 20px;
    background-color: #c1272d;
}
