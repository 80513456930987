.news{
    select{
        width: 285px;
        height: 50px;
        background-color: #f2f2f2;
        border: 0;
        border-radius: 3;
          text-indent: 15px;
        padding-left: 20px;
    }
}

.news-show{
    max-width: 1000px;
    padding: 70px 15px;
}

.news-show-title{
    display: flex;
    align-items: center;
    padding: 30px 0;
    width: 100%;
    border-top: 1px solid #b3b3b3;
     border-bottom: 1px solid #b3b3b3;
    span{
        font-size: 14px;
        color: #666666;
        padding: 0 35px;
    }
    p{
        font-size: 18px;
        color: #666666;
        line-height: 30px;
    }
    div{
          @include breakpoint($mobile) {
           margin-bottom: 20px;
        }
    }
}

.news-content{
    padding: 40px 0;
       border-bottom: 1px solid #b3b3b3;
       line-height: 30px;
        color: #666666;

}

.news-show-sec3{
    margin-top: 40px;
    a{
        margin: 0 5px;
    }
}


.event {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 3px;
    min-width: 120px;
    height: 40px;
    background-color: #c1272d;
}