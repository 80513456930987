.mobile-menu{
    width: 100%;
    ul{
        width: 100%;
        li{
            display: flex;
            align-items: center;
            height: 60px;
            padding:0 15px;

            a{
                display: flex;
                align-items: center;
                  height: 60px;
                  width: 100%;
                color: #4d4d4d;
                 border-bottom: 1px solid #e6e6e6;
            }
        }
    }
}

.sub_menu{
    background-color: #fff;
    display: none;
    ul{
        li{
            a{
                font-size: 13px;
                color: #808080;
            }
        }
    }
}

.drawer-menu > li {
    padding: 0 20px;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.drawer-hamburger {
    position: absolute;
    z-index: 111;
    top: 30px;
}

.drawer-hamburger-icon,
.drawer-hamburger-icon:after,
.drawer-hamburger-icon:before {
    width: 30px;
    border-radius: 10px;
    height: 4px;
    background-color: #4d4d4d;

}

.drawer--top .drawermo-hamburger,
.drawer--top.drawer-open .drawer-hamburger {
    right: 10px;
    width: 43px;
}



.drawer-menu-item {
    color: #4d4d4d;
    font-size: 15px;
    height: 60px;
}

.drawer-brand {
    width:100%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 3.75rem;
    display: block;
    padding-right: .75rem;
    padding-left: .75rem;
    text-decoration: none;
    color: #4F4C49;
    height: 60px
}
.drawer-nav {
    position: fixed;
    z-index: 105;
    top: 0;
    overflow: auto;
    width: 20rem;
    height: 100%;
    color: #4d4d4d;
    background-color: #f2f2f2;
}
.drawer--right .drawer-nav {
    z-index:105;
    right: -20.25rem;
    -webkit-transition: right .6s cubic-bezier(.19,1,.22,1);
    transition: right .6s cubic-bezier(.19,1,.22,1);
}
.drawer--right.drawer-open .drawer-hamburger {
    right: 0em;
    z-index: 1000;

}
.drawer-menu-item{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // align-items: center;
    // border-bottom: 1px solid #999999;
    p{
        margin: 0;
    }
    span{
        -ms-transform: scale(.5, .5); /* IE 9 */
        -webkit-transform: scale(.5, .5); /* Safari */
        transform: scale(.5, .5);
    }
    i{
        color: #00bdc5;
    }
}
.drawer--right .drawer-hamburger, .drawer--right.drawer-open .drawer-nav, .drawer--right.drawer-open .drawer-navbar .drawer-hamburger {
    z-index:105;
    top:0px;
}
.drawer-menu-item:hover {
    text-decoration: none;
    // color: #fff;
    // background-color: transparent;
}

.drawer-overlay {
    position: fixed;
    z-index: 104;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.2);
}

#lv2{
    background: transparent;
    width: 100%;
    height: 70px;
    border:0;
    text-align-last: center;

}


.breadcrumb{
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    margin: 0 auto;
    height: 60px;
    width: 100%;
    max-width: 1230px;
    padding: 0 10px;
    ul{
        width: 100%;
    }
    li{
        // min-width:100px; 
        a{
            padding: 0 10px;
            font-size: 14px;
            color: #898989;
        }
        a.active{
            color: #00d382;
        }
        span{
              color: #898989;
        }
    }
}


.pagination {
    margin-top: 40px;
    margin-bottom: 20px;
    //height: 60px;
   display: flex;
     justify-content: center;
    >li {
        display: flex;
       align-items: center;
       justify-content: center;
        width: 40px;
        height: 40px;
        margin: 0 5px;
        border-bottom: 0 !important;
        a {
            font-size: 13px;
          display: flex;
           align-items: center;
           justify-content: center;
            width: 40px;
            height: 40px;
            color: #9f9f9f;
        }
    }
    .active {
        position: relative;
        font-size: 13px;
        color: #3c323c;
        &:after{
            position: absolute;
            content: "\00a0";
            top: 32px;
            width: 20px;
            height: 2px;
            background-color: #4d4d4d;
        }
    }
}

@include breakpoint($mobile) {
    .pagination {
        margin-top: 40px;
        //height: 20px;
        display: flex;
        >li {
            display: flex;
           align-items: center;
            justify-content: center;
            width: 15px;
            height: 15px;
            margin: 0 3px;
            a {
                font-size: 13px;
                display: flex;
               align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                color: #9f9f9f;
            }
        }
    }
}

li.lang{
    padding: 0;
    display: flex;
    flex-direction: row;
    height: 75px;
    border-top: 1px solid #282e42;
    background: #24283b;
    position: absolute;
    top: 0;
    width: 100%;
    div{
        line-height: 65px;
        flex:1;
        text-align: center;
        a{
            color: #fff;
        }
    }
    .active{
        background-color: #282e42;
    }
}