#contact {
    position: relative;
    padding: 90px 0;
    width: 100%;
    min-height: 500px;
    background: transparent url('/images/contact_bg.jpg') center center no-repeat;
    background-size: cover;
}
 .contact-wrap{
        width: 100%;
    }
@include breakpoint($mobile) {
    .contact-wrap{
        width: 100%;
    }
}
.bg{
    top: 0;
    left: 0;
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: rgba(255,255,255,.6);
}

.contact-right {
    position: relative;
    z-index: 5;
    width: 50%;
     @include breakpoint($mobile-tablet) {
        width: 62%;
     }
      @include breakpoint($mobile) {
        width: 100%;
      }
    h1 {
        margin-bottom: 45px;
        font-size: 35px;
        font-family: Inconsolata◆;
        color: #666666;
    }
    .input-group {
        margin-bottom: 15px;
        width: 100%;
        width: 100%;
        display: flex;
        align-items: center;

        label {
            min-width: 80px;
        }
        textarea,
        input {
            padding-left: 20px;
            font-size: 14px;
            outline: none;
            width: 100%;
            max-width: 370px;
            height: 50px;
            border: 1px solid #dbdcdc;
            background: #fff;
             @include breakpoint($mobile) {
                width: 100%;
                max-width: 100%;
              }
        }
        @include breakpoint($mobile) {
        input[name='captcha']{
            width: 50% !important;
        }
    }
        textarea {
            height: 140px;
            padding-top: 20px;
        }
        .w240 {
            width: 100%;
            max-width: 240px;
            @include breakpoint($mobile) {
                width: 100%;
                max-width: 100%;
            }
        }
         @include breakpoint($mobile) {
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 30px;
            label{
                margin-bottom: 15px;
            }
         }
    }
}

.contact-more-bt {
    padding: 0;
    align-items: center;
    justify-content: space-between;
    width: 160px;
    height: 48px;
    background-color: #c1272d;
    color: #fff;
    border: 0;
    p {
        width: 195px;
        text-align: center;
    }
    i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 55px;
        height: 55px;
        background-color: #ff6e00;
        color: #fff;
    }
}

.btn-center {
    max-width: 370px;
    margin-top: 40px;
    @include breakpoint($mobile) {
        justify-content: center;
        margin-bottom: 20px;
        width: 100%;
    }
}

.contact-ul {
    width: 100%;
    max-width: 350px;
    li {
        height: 55px;
        display: flex;
        align-items: center;
        p {
            color: #fff;
            min-width: 100px;
        }
        border-bottom: 1px solid #fff;
    }
}
