
    #home-banners {
        height: 810px;
        @include breakpoint($tablet-desktop) {
            height: 500px;
        }
        @include breakpoint($mobile-tablet) {
            height: 400px;
        }
        @include breakpoint($mobile) {
            height: 200px;
        }
        .swiper-container {
            width: 100%;
            height: 100%;
        }
        .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;
            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
        }
        .main-bg {
            width: 100%;
            height: 100%;
            h1 {
                text-align: left;
                color: #fff;
                font-size: 35px;
                margin-bottom: 25px;
                max-width: 570px;
                line-height: 45px;
                font-family: 'Lato', sans-serif;
                font-weight: bold;
                letter-spacing: .06em;
            }
            p {
                text-align: left;
                margin-bottom: 10px;
                color: #fff;
                font-size: 15px;
                max-width: 420px;
                line-height: 30px;
            }
            .line {
                height: 2px;
                width: 120px;
                background-color: #fff;
                margin-bottom: 25px;
            }
        }
        .swiper-pagination {
            position: relative;
            top: -120px;
        }
        .swiper-pagination-bullet {
            border-radius: 0;
            opacity: 1;
            margin: 10px 10px;
            width: 70px;
            height: 6px;
            background-color: #f1f1f1;
        }
        .swiper-pagination-bullet-active {
            opacity: 1;
            background: #00d382;
        }
    }
    
    #home {
        width: 100%;
    }
    
    .home-sec1 {
        >ul {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                position: relative;
                padding: 25px;
                width: 50%;
                @include breakpoint($mobile-tablet) {
                    width: 100%;
                }
                @include breakpoint($mobile) {
                    width: 100%;
                }
                &:first-child {
                    &:after {
                        content: "";
                        background-color: rgba(193, 39, 45, .6);
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        content: " ";
                        z-index: 10;
                    }
                }
                &:last-child {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    background-color: #f2f2f2;
                    p {
                        max-width: 650px;
                        font-size: 19px;
                        line-height: 33px;
                        color: #666666;
                    }
                    @include breakpoint($tablet-desktop) {
                        p {
                            max-width: 650px;
                            font-size: 15px;
                            line-height: 30px;
                            color: #666666;
                        }
                    }
                    @include breakpoint($mobile) {
                        min-height: 300px;
                        p {
                            max-width: 650px;
                            font-size: 13px;
                            line-height: 20px;
                            color: #666666;
                        }
                    }
                }
                a {
                    border: 1px solid #fff;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    z-index: 11;
                    h1 {
                        width: 102px;
                        font-size: 35px;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                           @include breakpoint($mobile) {
                            font-size: 20px;
                           }
                    }
                }
            }
            @include breakpoint($mobile) {
                padding: 20px 15px;
                top: 0;
            }
        }
    }
    
    .home-sec2 {
        padding-top: 90px;
        width: 100%;
        min-height: 420px;
        h1 {
            text-align: center;
            font-size: 35px;
            color: #333333;
            margin-bottom: 15px;
            font-weight: bold;
        }
        .line {
            width: 80px;
            height: 4px;
            background-color: #e40012;
        }
        ul {
            padding: 80px 0;
            width: 100%;
            li {
                display: flex;
                align-items: center;
                //min-height: 80px;
                padding: 20px 0;
                width: 100%;
                border-bottom: 1px solid #e6e6e6;
                a {
                    display: flex;
                    align-items: center;
                    //min-height: 50px;
                    width: 100%;
                    span {
                        color: #666666;
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-family: 'Lato', sans-serif;
                        padding: 0 20px;
                        min-height: 35px;
                        line-height: 35px;
                        border-right: 1px solid #e6e6e6;
                    }
                    div {
                        padding: 0 20px;
                        justify-content: space-between;
                        width: 100%;
                    }
                    p {
                        font-size: 15px;
                        display: flex;
                        align-items: center;
                        color: #666666;
                    }
                    p.more {
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 30px;
                        border-radius: 15px;
                        i {
                            color: #808080;
                        }
                        border: 1px solid #808080;
                    }
                    &:hover {
                        p {
                            color: #e40012;
                        }
                        p.more {
                            background-color: #808080;
                            color: #fff;
                        }
                    }
                }
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
    
    @include breakpoint($mobile) {
        .home-sec2 {
            padding-top: 45px;
            .line {
                margin-bottom: 0px;
            }
            ul {
                padding: 40px 0;
                width: 100%;
                max-width: 1000px;
                li {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    border-bottom: 1px solid #e6e6e6;
                    a {
                        flex-direction: column;
                        width: 100%;
                        span {
                            padding: 0;
                            margin-bottom: 15px;
                            border-right: 0px;
                        }
                        div {
                            padding: 0;
                            justify-content: space-between;
                            width: 100%;
                        }
                        p {
                            font-size: 15px;
                            display: flex;
                            align-items: center;
                            color: #666666;
                        }
                        p.more {
                            justify-content: center;
                            align-items: center;
                            width: 40px;
                            height: 30px;
                            border-radius: 40%;
                            i {
                                color: #808080;
                            }
                            border: 1px solid #808080;
                        }
                        &:hover {
                            p {
                                color: #e40012;
                            }
                            p.more {
                                background-color: #808080;
                                color: #fff;
                            }
                        }
                    }
                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
    
    .home-sec3 {
        padding-bottom: 80px;
        //padding-top: 90px;
        width: 100%;
        min-height: 420px;
        h1 {
            text-align: center;
            font-size: 35px;
            color: #333333;
            margin-bottom: 15px;
            font-weight: bold;
        }
        .line {
            width: 80px;
            height: 4px;
            background-color: #e40012;
            margin-bottom: 50px;
        }
    }
    
    .product-list {
        width: 100%;
        .block1 {
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    background-color: red;
                    height: 370px;
                    width: calc(96%/3);
                    margin: 0 1%;
                    margin-bottom: 2%;
                    @include gird(2);
                    &:first-child {
                        margin-left: 0;
                        width: calc(96%/3*2 + 2%);
                    }
                    &:nth-child(3) {
                        margin-left: 0;
                        margin-right: 1%;
                    }
                    &:nth-child(4) {
                        margin-right: 1%;
                    }
                    &:last-child {
                        margin-left: 1%;
                        margin-right: 0;
                    }
                    @include breakpoint($mobile-tablet) {
                        height: 200px;
                    }
                    @include breakpoint($mobile) {
                        @include gird(1);
                        height: 250px;
                        margin-bottom: 8%;
                        width: calc(100%);
                        &:first-child {
                            margin-left: 0;
                            width: calc(100%);
                        }
                        &:nth-child(3) {
                            margin-left: 0;
                            margin-right: 0%;
                        }
                        &:nth-child(4) {
                            margin-right: 0%;
                        }
                        &:last-child {
                            margin-left: 0%;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        .block2 {
            display: flex;
            .left {
                min-height: 370px;
                width: calc(96%/3);
                margin-right: 1%;
                ul {
                    width: 100%;
                    li {
                        width: 100%;
                    }
                }
                @include breakpoint($mobile) {
                    width: 100%;
                    margin-bottom: 6%;
                    margin-right: 0;
                }
            }
            .right {
                width: calc(96%/3*2 + 2%);
                margin-left: 1%;
                @include breakpoint($mobile) {
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: 6%;
                }
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    li {
                        height: 370px;
                        width: calc(97%/2);
                        margin: 0 1.5%;
                        margin-bottom: 3%;
                        @include gird(2);
                        &:last-child {
                            width: 100%;
                            margin-bottom: 0;
                            margin-right: 0;
                        }
                        @include breakpoint($mobile-tablet) {
                            height: 200px;
                        }
                        @include breakpoint($mobile) {
                            height: 250px;
                            @include gird(1);
                            width: calc(100%);
                            margin-bottom: 6%;
                        }
                    }
                }
            }
        }
    }


.product-title-a{
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    padding-left: 45px;
    padding-top: 50px;
    h3{
        font-size: 22px;
        font-weight: bold;
        color: #333333;
        line-height: 35px;
    }
    span{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        bottom:0;
        width: 60px;
        height: 60px;
        background-color: #c1272d;
        i{
            color:#fff;
        }
    }
}