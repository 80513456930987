.category {
    width: 100%;
    padding: 70px 15px;
}

ul.bar {
    display: flex;
    align-items: center;
    font-size: 13px;
    height: 50px;
    li {
        color: #c1272d;
        margin: 0 5px;
        &:first-child {
            margin-left: 0;
        }
        color: #666666;
        a {
            color: #666666;
        }
    }
}

.product-list {
    margin-bottom: 2%;
}

.bar-wrap {
    background-color: #f2f2f2;
}

.product {
    padding-bottom: 40px;
    div {
        padding-top: 90px;
        display: flex;
        @include breakpoint($mobile) {
            flex-direction: column;
        }
        h1 {
            min-width: 40%;
            font-size: 30px;
            color: #666666;
            @include breakpoint($mobile) {
                width: 100%;
                margin-bottom: 40px;
            }
        }
        p {
            font-size: 17px;
            line-height: 30px;
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                width: calc(94%/4);
                @include gird(4);
                margin: 0 1%;
                margin-bottom: 6%;
                img {
                    width: 100%;
                }
                p {
                    font-size: 17px;
                    color: #000;
                }
                @include breakpoint($mobile) {
                    @include gird(2);
                    width: calc(98%/2);
                    margin-bottom: 40px;
                }
            }
        }
    }
}

.product-banner {
    height: 450px;
    @include breakpoint($mobile) {
        height: 300px;
    }
}

.modal-container {
    display: flex;
    width: 90vw;
    max-width: 1230px;
    padding: 60px 100px;
    margin: 20px 0;
    box-shadow: 0 0.1em 0.4em rgba(0, 0, 0, .3);
    background-color: #f2f2f2;
      @include breakpoint($mobile) {
         padding: 30px 30px;
         padding-top: 70px;
    }
    .left {
        width: 40%;
        img {
            width: 100%;
        }
        @include breakpoint($mobile-tablet) {
            width: 100%;
        }
         @include breakpoint($mobile) {
            width: 100%;
        }
    }
    .right {
        position: relative;
        padding-left: 40px;
        width: 60%;
        h1 {
            font-size: 20px;
            color: #666666;
            margin-bottom: 40px;
        }
        p {
            font-size: 17px;
            color: #666666;
            line-height: 30px;
        }
        .cate {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            bottom: 0;
            p {
                font-size: 13px;
                display: flex;
                align-items: center;
                width: calc(94%/4);
                @include gird(4);
                margin: 0 1%;
                margin-top: 2%;
                img {
                    margin-right: 10px;
                }
            }
            @include breakpoint($mobile-tablet) {
                position: relative;
                margin-top: 20px;
            }
             @include breakpoint($mobile) {
                position: relative;
                margin-top: 20px;
                p{
               	width: calc(94%/2);
               	margin-top: 4%;
                @include gird(2);
                }
            }
        }
        @include breakpoint($mobile-tablet) {
        	margin-top: 40px;
           width: 100%;
           padding-left: 0;
        }
         @include breakpoint($mobile) {
        	margin-top: 40px;
           width: 100%;
           padding-left: 0;
        }
    }
}

.fancybox-slide > * {
    background-color: transparent;
    overflow: auto;
}

.fancybox-close-small:after {
    content: '×';
    position: absolute;
    top: 43px;
    border: 1px solid #666666;
    right: 24px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font: 40px/48px Arial,"Helvetica Neue",Helvetica,sans-serif;
    color: #888;
    font-weight: 300;
    text-align: center;
    border-radius: 0;
    background-color: transparent;
    transition: background .25s;
    z-index: 2;
}
