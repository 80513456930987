@mixin max-width($width) {
    max-width: $width;
    width: 100%;
}

@mixin gird($nth){
    &:first-child{
        margin-left: 0;
    }
    &:nth-child(#{$nth}n){
        margin-right: 0;
    }
    &:nth-child(#{$nth}n+1){
        margin-left: 0;
    }
}

@mixin center-block {
    margin-left: auto;
    margin-right: auto;
}

@mixin ease($time) {
    transition: all $time ease;
}

@mixin appearance($style) {
    appearance: $style;
    -moz-appearance: $style;
    -webkit-appearance: $style;
}

@mixin clearInputStyle {
    @include appearance(none);
    outline: none;
    background: #fff;
    border: 0px;
    padding: 0;
    border-radius: 0px;
}


@mixin LatoFont {
    font-family: 'Lato', Microsoft JhengHei, sans-serif;
}

@mixin YkFont{
    font-family: 'Yanone Kaffeesatz', Microsoft JhengHei, sans-serif;
}

@mixin MonFont {
    font-family: 'Montserrat', Microsoft JhengHei, sans-serif;
}

@mixin ArialFont{
    font-family: Arial, Microsoft JhengHei, sans-serif;
}