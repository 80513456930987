.wrap {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
}

.hidden-xs {
    @include breakpoint($mobile) {
        display: none !important;
    }
}

.hidden-sm {
    @include breakpoint($mobile-tablet) {
        display: none !important;
    }
}

.hidden-md {
    @include breakpoint($tablet-desktop) {
        display: none !important;
    }
}

.hidden-lg {
    @include breakpoint($desktop) {
        display: none !important;
    }
}

.visible-xs {
    @include breakpoint($desktop) {
        display: none !important;
    }
     @include breakpoint($tablet-desktop) {
        display: none !important;
    }
    @include breakpoint($mobile-tablet) {
        display: none !important;
    }
    @include breakpoint($mobile) {
        display: block !important;
    }
}

.visible-sm {
    @include breakpoint($desktop) {
        display: none !important;
    }
     @include breakpoint($tablet-desktop) {
        display: none !important;
    }
    @include breakpoint($mobile-tablet) {
        display: block !important;
    }
    @include breakpoint($mobile) {
        display: none !important;
    }
}

.visible-md {
    @include breakpoint($desktop) {
        display: block !important;
    }
    @include breakpoint($mobile-tablet) {
        display: none !important;
    }
    @include breakpoint($mobile) {
        display: none !important;
    }
}


/* flex */

.column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
}
.cas {
    align-items: flex-start;
}

.cje {
   justify-content:  flex-end;
}


.cae {
    align-items: flex-end;
}

.cje {
   justify-content:  flex-end;
}

.cac {
    align-items: center;
}

.cjc {
    justify-content: center;
}

.row {
    display: flex;
    display: -webkit-flex;
}

.rac {
    align-items: center;
    -webkit-align-items: center;
}

.rae {
    align-items: flex-end;
    -webkit-align-items: flex-end;
}

.rje {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

.rjc {
    justify-content: center;
    -webkit-justify-content: center;
}

.jsb {
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.jsa {
    justify-content: space-around;
    -webkit-justify-content: space-around;
}

.mjtc{
    @include breakpoint($mobile) {
        flex-direction: column;
    }
}

.mtjtc{
    @include breakpoint($mobile-tablet) {
        flex-direction: column;
    }
}

.tdjtc{
    @include breakpoint($tablet-desktop) {
        flex-direction: column;
    }
}

::-webkit-input-placeholder {
    /* Chrome */
    color: #666666;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #666666;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #666666;
}

:-moz-placeholder {
    /* Firefox 4 - 18 */
    color: #666666;
}

.mtjtc{
    @include breakpoint($mobile-tablet) {
        flex-direction: column;
    }
}

.tdjtc{
    @include breakpoint($tablet-desktop) {
        flex-direction: column;
    }
}