#header {
    width: 100%;
    background-color: #fff;
}

.header-wrap {
    height: 80px;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

.menu {
    ul {
        li {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            &:last-child {
                border-right: 0px;
            }
            a {
                font-size: 17px;
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000000;
                padding: 0 25px;
            }
        }
        .lang-style{
            margin-left: 20px;
            a{
                font-size: 13px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #000;
                width: 36px;
                height: 36px;
                padding: 0 10px;
                border-radius: 20px;
                margin: 0 5px;
            }
            .active{
                background-color: #000000;
                color: #fff;
            }
        }
    }
}






