.banner-img{
	width: 100%;
	height: 265px;
	background: url('/images/banner.jpg') center center  no-repeat;background-size:cover;
	h1{
		font-size: 30px;
		font-weight: bold;
	}
	ul{
		margin-top: 10px;
		display: flex;
		align-items: center;
		font-size: 13px;
		li{
			color: #c1272d;
			margin: 0 5px;
			&:first-child{
				margin-left: 0;
			}
		}
	}
}

.about{
	width: 100%;
}

.about-sec1{
	padding: 90px 15px;
	p{
		margin: 0 20px;
		max-width: 530px;
		line-height: 30px;
		font-size: 18px;
	}
	 @include breakpoint($mobile-tablet) {
        flex-direction: column;
        p{
        	max-width: 100%;
        	margin: 20px 0;
        }
        img{
        	width: 100%;
        }
    }
    @include breakpoint($mobile) {
        flex-direction: column;
        p{
        	max-width: 100%;
        	margin: 20px 0;
        }
        img{
        	width: 100%;
        }
    }
    .mtjtc{
    	padding: 0;
    }
}