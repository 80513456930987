

@import '~normalize.css/normalize.css';
@import '~breakpoint-sass/stylesheets/breakpoint';
@import '~animate.css/animate.min.css';
@import '~sweetalert/dist/sweetalert.css';
@import '~swiper/dist/css/swiper.min.css';

@import 'variables';
@import 'mixins';
@import 'style';

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
          font-family: Microsoft JhengHei,sans-serif;
}
html{
    font-size: 100%;
}

body{
  font-size: 1rem;
}

a{
    text-decoration: none;
}

ul, li{
    margin: 0;
    padding: 0;
    list-style-type: none;
}

#app{
    // display: flex;
    // min-height: 100vh;
    // flex-direction: column;
}

h1, h2, h3, p{
    margin: 0px;
    padding: 0px;
    font-weight: normal;
    font-size: 1rem;
}

h1{
}


#main-content{
    flex: 1 0 auto;
}

header#header, footer#footer{
    flex-shrink: 0;
}







@import 'header';
@import 'footer';
@import 'home';
@import 'category';
@import 'contact';
@import 'news';
@import 'about';
@import 'drawer';


